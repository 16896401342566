import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../../components/seo/SEO';
import Img from 'gatsby-image';
import styles from './ServiceDetailed.module.scss';
import Layout from '../../components/layout/Layout';

export const query = graphql`
  query {
    ammoniaship: file(relativePath: { eq: "ammonia/ammoniaship.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const AmmoniaPage = (props: any) => {
  const { data } = props;
  return (
    <>
      <SEO title={'Ammonia. Read about which services we can provide you with in this field.'} />
      <Layout>
        <div className="grid_text_image grid_vertical_padding sectionVeryLong pb-48 mb-48">
          <div>
            <h1 className={styles.welcomeText}>Ammonia</h1>
            <p className={styles.descriptionText}>
            Ammonia is expected to become a popular zero emission fuel for certain categories of 
            maritime vessels, due to a lower reactivity and the relative ease with which it can 
            be handled as a liquid near ambient temperatures/pressures. Among its drawbacks are 
            toxicity and odour at very low concentrations. For facilities or vessels handling 
            ammonia, safety studies will focus primarily on dispersion and toxicity assessment. 
            Our spreadsheet-based ammonia models can give quick estimates of hazard distances, 
            supplemented by dispersion simulations using CFD. 
            </p>
          </div>
          <div className={styles.imageContainer}>
            <figure className="headerImageHorizontal fullWidthMobile">
              <Img fluid={data.ammoniaship.childImageSharp.fluid} alt="Ammonia" />
            </figure>
          </div>
        </div>

        <div className={`grid_vertical_padding ${styles.articleTitle}`}>Published works:</div>
        <div className={`grid_vertical_padding ${styles.articleContainer}`}>
          <div className={styles.articleColumn}>
          <div className={styles.article}>
              <a
                href="https://www.oceanhywaycluster.no/news/hyinfra-project-first-deliverables-and-an-interactive-map"
                target="_blank"
                rel="noopener noreferrer"
                title="Hydrogen and Ammonia Infrastructure - Safety and Risk
                Information and Guidance"
              >
                Hansen, O.R. (2020). Hydrogen and Ammonia Infrastructure - Safety and Risk
                Information and Guidance
              </a>
            </div>
          <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/341611478_CFD_Simulation_of_NH3_Release_and_Detection_in_Refrigerated_Spaces"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD Simulation of NH3 Release and Detection in Refrigerated Spaces"
              >
                Greulich, W., Myhr, E. L., Hansen, O. R. (2020). CFD Simulation of NH3 Release and
                Detection in Refrigerated Spaces
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.iiar.org/IIAR/IIAR_Publications/IIAR_Technical_Papers/2016_Technical_Papers.aspx"
                target="_blank"
                rel="noopener noreferrer"
                title="Quantitative Risk Analyses of Untreated, Vertical
              Pressure Relief Venting Using a Computational Fluid Dynamics Simulation"
              >
                Greulich, W., Hansen, O. R. (2016). Quantitative Risk Analyses of Untreated,
                Vertical Pressure Relief Venting Using a Computational Fluid Dynamics Simulation.
              </a>
            </div>
          </div>
          <div className={styles.articleColumn}>
            <div className={styles.article}>
              <a
                href="https://ams.confex.com/ams/91Annual/webprogram/Paper186588.html"
                target="_blank"
                rel="noopener noreferrer"
                title="CFD simulations of the Jack Rabbit ammonia and chlorine release experiments using FLACS"
              >
                Gavelli, F., Ichard, M., Davis, S., Hansen, O. R. (2011). CFD simulations of the
                Jack Rabbit ammonia and chlorine release experiments using FLACS.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/publication/228979727_62_RELEASES_OF_PRESSURIZED_LIQUEFIED_GASES_SIMULATIONS_OF_THE_DESERT_TORTOISE_TEST_SERIES_WITH_THE_CFD_MODEL_FLACS"
                target="_blank"
                rel="noopener noreferrer"
                title="Releases of pressurized liquefied
                gases: simulations of the Desert Tortoise test series with the CFD model FLACS"
              >
                Ichard, M., Hansen, O. R., Melheim, J. (2010). Releases of pressurized liquefied
                gases: simulations of the Desert Tortoise test series with the CFD model FLACS.
              </a>
            </div>
            <div className={styles.article}>
              <a
                href="https://www.researchgate.net/profile/Olav_Hansen/publications"
                target="_blank"
                rel="noopener noreferrer"
                title="ResearchGate"
              >
                More at ResearchGate
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AmmoniaPage;
